import type { FulfillmentsClient } from 'root/api/fulfillmentsClient';
import { DispatchType } from 'root/types/businessTypes';
import type {
  DispatchesInfo,
  DispatchState,
  Operation,
  Address,
  TimeSlot,
} from 'root/types/businessTypes';
import type {
  FulfillmentMethod,
  TimeOfDay,
  TimeOfDayRange,
} from '@wix/ambassador-restaurants-v1-fulfillment-method/types';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import { getMonitoredApiCall } from 'root/api/utils/getMonitoredApiCall';
import type { ReportError } from '@wix/yoshi-flow-editor';
import { createTimeRange } from 'root/api/utils/TimeSlotProcessor';
import type { FulfillmentDetails } from '@wix/ambassador-restaurants-operations-v1-operation/types';
import { FulfillmentTimeType } from '@wix/ambassador-restaurants-operations-v1-operation/types';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import { DateTime } from 'luxon';
import { DayOfWeek } from '@wix/ambassador-restaurants-v1-fulfillment-method/types';
import { state } from './RootState';
import type { Cart } from '@wix/ambassador-ecom-v1-cart/types';

type MonitoredResponse<T> = { data?: T; error?: Error } | undefined;

const getConsistentValue = (values: (string | null | undefined)[]) => {
  return values.length === 0 || values.some((value) => value !== values[0])
    ? undefined
    : values[0] ?? undefined;
};

const DAY_OF_WEEK = {
  [DayOfWeek.MON]: 1,
  [DayOfWeek.TUE]: 2,
  [DayOfWeek.WED]: 3,
  [DayOfWeek.THU]: 4,
  [DayOfWeek.FRI]: 5,
  [DayOfWeek.SAT]: 6,
  [DayOfWeek.SUN]: 7,
};

const convertTimeOfDayToDateTime = (time: TimeOfDay, timezone?: string) => {
  return DateTime.fromObject({
    hour: time.hours,
    minute: time.minutes,
  }).setZone(timezone, { keepLocalTime: true });
};

const isInTimeRange = (time: DateTime, range: TimeOfDayRange, timeZone?: string) => {
  const startTime = range.startTime ? convertTimeOfDayToDateTime(range.startTime, timeZone) : time;
  const endTime = range.endTime ? convertTimeOfDayToDateTime(range.endTime, timeZone) : time;
  return time.toMillis() >= startTime.toMillis() && time.toMillis() <= endTime.toMillis();
};

export const isFulfillmentAvailable = (fulfillment: FulfillmentMethod, operation: Operation) => {
  const { availability: { timeZone, availableTimes } = {} } = fulfillment;
  const { asapOptions: { maxInMinutes, rangeInMinutes } = {} } = operation;
  const deliveryTime = fulfillment.deliveryOptions?.deliveryTimeInMinutes ?? 0;

  const zone = timeZone as string | undefined;
  const timeNow = DateTime.local({ zone }).plus({
    minutes: (maxInMinutes ?? rangeInMinutes?.min ?? 0) + deliveryTime,
  });
  return !!availableTimes?.some((availableTime) => {
    const { dayOfWeek, timeRanges } = availableTime;
    const dayOfWeekNumber = DAY_OF_WEEK[dayOfWeek as DayOfWeek];
    const isAvailable =
      dayOfWeekNumber === timeNow.weekday &&
      !!timeRanges?.some((range) => isInTimeRange(timeNow, range, zone));
    return isAvailable;
  });
};

const processFulfillments = async (
  allFulfillmentMethods: Promise<MonitoredResponse<FulfillmentMethod[]>>,
  firstAvailableTimeSlotPromise: Promise<MonitoredResponse<TimeSlot[]>>,
  operation: Operation,
  reportError?: ReportError,
  sentry?: ErrorMonitor
) => {
  const shouldConsiderAvailability = await firstAvailableTimeSlotPromise.then((res) => {
    const timeSlots = res?.data ?? [];
    return timeSlots.length > 0;
  });
  sentry?.addBreadcrumb({
    type: 'initDispatchState',
    category: 'processFulfillments',
    message: 'creating dispatchesInfo from query fulfillment methods',
  });
  try {
    return allFulfillmentMethods.then((res) => {
      const fulfillments = res?.data ?? [];
      const pickupFulfillment = fulfillments.find(
        (fulfillment) => fulfillment.type === 'PICKUP' && fulfillment.enabled
      );
      let deliveryFulfillments = fulfillments.filter(
        (fulfillment) => fulfillment.type === 'DELIVERY' && fulfillment.enabled
      );
      const isPickupConfigured = !!pickupFulfillment;
      const isDeliveryConfigured = deliveryFulfillments.length > 0;

      if (shouldConsiderAvailability) {
        deliveryFulfillments = deliveryFulfillments.filter((fulfillment) =>
          isFulfillmentAvailable(fulfillment, operation)
        );
      }

      const dispatchesInfo: DispatchesInfo = {} as DispatchesInfo;

      if (isPickupConfigured) {
        const pickupAddress = pickupFulfillment?.pickupOptions?.address
          ? (pickupFulfillment.pickupOptions.address as Address)
          : undefined;

        dispatchesInfo[DispatchType.PICKUP] = {
          address: pickupAddress,
          minOrder: pickupFulfillment?.minimumOrderAmount ?? undefined,
        };
      }

      if (isDeliveryConfigured) {
        const deliveryFee = getConsistentValue(deliveryFulfillments.map((f) => f.fee));
        const freeFulfillmentPriceThreshold = getConsistentValue(
          deliveryFulfillments.map((f) => f.deliveryOptions?.freeDeliveryThreshold)
        );

        const minOrder = getConsistentValue(deliveryFulfillments.map((f) => f.minimumOrderAmount));

        const { asapOptions: { maxInMinutes, rangeInMinutes } = {} } = operation;

        const fulfillmentDetails: FulfillmentDetails[] = deliveryFulfillments.map((f) => {
          const deliveryTime = f.deliveryOptions?.deliveryTimeInMinutes ?? 0;
          const timeObject = maxInMinutes
            ? {
                maxTimeOptions: deliveryTime + maxInMinutes,
                FulfillmentTimeType: FulfillmentTimeType.MAX_TIME,
              }
            : {
                durationRangeOptions: {
                  minDuration: deliveryTime + (rangeInMinutes?.min ?? 0),
                  maxDuration: deliveryTime + (rangeInMinutes?.max ?? 0),
                },
                fulfillmentTimeType: FulfillmentTimeType.DURATION_RANGE,
              };
          return timeObject;
        }) as FulfillmentDetails[];

        dispatchesInfo[DispatchType.DELIVERY] = {
          minOrder,
          deliveryFee,
          freeFulfillmentPriceThreshold,
          ...((maxInMinutes || rangeInMinutes) &&
            fulfillmentDetails.length > 0 &&
            createTimeRange(fulfillmentDetails)),
        };
      }
      return { dispatchesInfo, isPickupConfigured, isDeliveryConfigured };
    });
  } catch (error) {
    reportError?.(error as Error);
    sentry?.captureException(error as Error);
    return {
      dispatchesInfo: {} as DispatchesInfo,
      isPickupConfigured: false,
      isDeliveryConfigured: false,
    };
  }
};

const processFirstAvailableTimeSlots = async (
  firstAvailableTimeSlotPromise: Promise<MonitoredResponse<TimeSlot[]>>,
  reportError?: ReportError,
  sentry?: ErrorMonitor
) => {
  sentry?.addBreadcrumb({
    type: 'initDispatchState',
    category: 'processFirstAvailableTimeSlots',
    message: 'creating dispatchesInfo from http request',
  });
  try {
    return firstAvailableTimeSlotPromise.then((res) => {
      const timeSlots = res?.data ?? [];
      const dispatchesInfo = timeSlots.reduce((acc, timeSlot) => {
        acc[timeSlot.dispatchType] = {
          selectedTimeSlot: timeSlot,
          minDate: timeSlot.startTime ?? undefined,
        };
        return acc;
      }, {} as DispatchesInfo);
      return { dispatchesInfo };
    });
  } catch (error) {
    reportError?.(error as Error);
    sentry?.captureException(error as Error);
    return { dispatchesInfo: {} as DispatchesInfo };
  }
};

export const isPersistedInfoValid = async (
  persistedState: DispatchState,
  dispatchType: DispatchType,
  client: FulfillmentsClient,
  timezone: string
) => {
  const persistedInfo = persistedState.dispatchesInfo[dispatchType];
  const isDelivery = dispatchType === DispatchType.DELIVERY;
  const deliveryAddress = isDelivery ? persistedInfo.address : undefined;
  const selectedTimeSlot = persistedInfo.selectedTimeSlot;
  if (selectedTimeSlot) {
    const date = selectedTimeSlot.startTime.setZone(timezone).startOf('day').toJSDate();
    const timeSlots = await client.fetchAvailableTimeSlotsForDate({
      date,
      deliveryAddress,
      dispatchType,
    });
    return timeSlots.some((slot) => slot.id === selectedTimeSlot.id);
  }
  return false;
};

export const getPersistedStateValidityByType = async (
  client: FulfillmentsClient,
  timezone: string,
  persistedState?: DispatchState,
  sentry?: ErrorMonitor,
  reportError?: ReportError
) => {
  sentry?.addBreadcrumb({
    type: 'initDispatchState',
    category: 'validate persisted state',
  });
  try {
    return Promise.all(
      [DispatchType.PICKUP, DispatchType.DELIVERY].map(async (dispatchType) => {
        let validity = false;
        if (persistedState?.dispatchesInfo[dispatchType]) {
          validity = await isPersistedInfoValid(persistedState, dispatchType, client, timezone);
        }
        return { [dispatchType]: validity };
      })
    ).then((validities) => validities.reduce((acc, validity) => ({ ...acc, ...validity }), {}));
  } catch (error) {
    reportError?.(error as Error);
    sentry?.captureException(error as Error);
    return { [DispatchType.PICKUP]: false, [DispatchType.DELIVERY]: false };
  }
};

export const resolveSelectedDispatchType = ({
  isPickupConfigured,
  isDeliveryConfigured,
  isPersistedDelivery,
  isPersistedPickup,
  operation,
  dispatchesInfo,
}: {
  isPickupConfigured: boolean;
  isDeliveryConfigured: boolean;
  isPersistedDelivery: boolean;
  isPersistedPickup: boolean;
  operation: Operation;
  dispatchesInfo: DispatchesInfo;
}) => {
  const isPickupAvailable =
    isPickupConfigured && dispatchesInfo[DispatchType.PICKUP]?.selectedTimeSlot;

  const isDeliveryAvailable =
    isDeliveryConfigured && dispatchesInfo[DispatchType.DELIVERY]?.selectedTimeSlot;

  const noDispatchesAvailable = !isPickupAvailable && !isDeliveryAvailable;

  const hasOnlyDeliveryConfigured = isDeliveryConfigured && !isPickupConfigured;

  const isPickupByDefault =
    (isPickupAvailable || (noDispatchesAvailable && isPickupConfigured)) &&
    (operation.defaultDispatchType === DispatchType.PICKUP || isPersistedPickup);

  const shouldHaveDeliverySelected =
    ((!isPickupByDefault || isPersistedDelivery) && isDeliveryAvailable) ||
    hasOnlyDeliveryConfigured;

  return shouldHaveDeliverySelected ? DispatchType.DELIVERY : DispatchType.PICKUP;
};

const isPersistedSelectedDispatchType = (
  dispatchType: DispatchType,
  cart?: Cart,
  persistedState?: DispatchState
) => {
  return (
    !!cart?.selectedShippingOption?.code?.includes(String(dispatchType)) ||
    persistedState?.selectedDispatchType === dispatchType
  );
};

export const initDispatchState = async (
  fulfillmentsClient: FulfillmentsClient,
  operationPromise: Promise<Operation>,
  timezone: string,
  cartPromise?: Promise<Cart | undefined>,
  persistedState?: DispatchState,
  fedopsLogger?: FedopsLogger,
  reportError?: ReportError,
  sentry?: ErrorMonitor
): Promise<DispatchState> => {
  const [validityByType, cart] = await Promise.all([
    getPersistedStateValidityByType(
      fulfillmentsClient,
      timezone,
      persistedState,
      sentry,
      reportError
    ),
    cartPromise,
  ]);
  if ([DispatchType.PICKUP, DispatchType.DELIVERY].every((type) => validityByType[type])) {
    return persistedState as DispatchState;
  }
  const contactInfo = cart?.contactInfo;
  const operation = await operationPromise;

  const PERSISTED_SELECTED_DISPATCH_TYPE = [DispatchType.DELIVERY, DispatchType.PICKUP].reduce(
    (acc, type) => {
      acc[type] = isPersistedSelectedDispatchType(type, cart, persistedState);
      return acc;
    },
    {} as Record<DispatchType, boolean>
  );

  const address = PERSISTED_SELECTED_DISPATCH_TYPE[DispatchType.DELIVERY]
    ? (contactInfo?.address as Address) ??
      persistedState?.dispatchesInfo[DispatchType.DELIVERY]?.address
    : undefined;

  const onError = () => {
    state.pubsub.publish('onFetchFailed', { oloState: 'errorState' });
  };

  const getMonitoredFirstAvailableTimeSlotClient = () =>
    getMonitoredApiCall({
      callback: () => fulfillmentsClient.fetchFirstAvailableTimeSlot(address),
      fedops: {
        start: fedopsLogger?.fetchFirstAvailableTimeSlotStarted,
        end: fedopsLogger?.fetchFirstAvailableTimeSlotEnded,
      },
      reportError,
      sentry,
      onError,
    });

  const firstAvailableTimeSlotsPromise = getMonitoredFirstAvailableTimeSlotClient();

  const getMonitoredAllFulfillmentsClient = () =>
    getMonitoredApiCall({
      callback: () => fulfillmentsClient.fetchAllFulfillments(operation.fulfillmentIds),
      fedops: {
        start: fedopsLogger?.fetchAllFulfillmentsStarted,
        end: fedopsLogger?.fetchAllFulfillmentsEnded,
      },
      reportError,
      sentry,
    });

  const allFulfillmentsPromise = getMonitoredAllFulfillmentsClient();

  const [firstAvailableTimeSlotsProcessResult, allFulfillmentsProcessResult] = await Promise.all([
    processFirstAvailableTimeSlots(firstAvailableTimeSlotsPromise, reportError, sentry),
    processFulfillments(
      allFulfillmentsPromise,
      firstAvailableTimeSlotsPromise,
      operation,
      reportError,
      sentry
    ),
  ]);

  const dispatchesInfo: DispatchesInfo = {} as DispatchesInfo;

  const { isPickupConfigured, isDeliveryConfigured } = allFulfillmentsProcessResult;

  if (isPickupConfigured) {
    dispatchesInfo[DispatchType.PICKUP] = {
      ...(validityByType[DispatchType.PICKUP]
        ? persistedState?.dispatchesInfo[DispatchType.PICKUP]
        : firstAvailableTimeSlotsProcessResult.dispatchesInfo[DispatchType.PICKUP]),
      ...allFulfillmentsProcessResult.dispatchesInfo[DispatchType.PICKUP],
    };
  }

  if (isDeliveryConfigured) {
    dispatchesInfo[DispatchType.DELIVERY] = {
      address,
      ...(validityByType[DispatchType.DELIVERY]
        ? persistedState?.dispatchesInfo[DispatchType.DELIVERY]
        : firstAvailableTimeSlotsProcessResult.dispatchesInfo[DispatchType.DELIVERY]),
      ...allFulfillmentsProcessResult.dispatchesInfo[DispatchType.DELIVERY],
    };
  }

  const selectedDispatchType = resolveSelectedDispatchType({
    isPickupConfigured,
    isDeliveryConfigured,
    isPersistedDelivery: PERSISTED_SELECTED_DISPATCH_TYPE[DispatchType.DELIVERY],
    isPersistedPickup: PERSISTED_SELECTED_DISPATCH_TYPE[DispatchType.PICKUP],
    operation,
    dispatchesInfo,
  });

  return {
    selectedDispatchType,
    dispatchesInfo,
  };
};
